.selectFieldCustom {
	background: whitesmoke;
	display: flex;
	flex-flow: column;
	padding: 10px;
	gap: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	max-height: 150px;
	overflow-y: scroll;
	position: absolute;
	width: 100%;
	z-index: 2;
	display: none;
	transition: var(--tran-02);
}

span.selectFieldCustom__Item {
	width: 100%;
	text-align: left;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid lightgray;
	background: white;
	padding: 10px;
	border-radius: 5px;
  cursor: pointer;
  transition: var(--tran-03);
}

span.selectFieldCustom__Item:hover{
  transform: scale(1.03);
}

.selectFieldCustom.openSelect{
  display: flex;
}