.page {
	padding: 20px;
	padding-left: 70px;
	min-height: 90vh;
	max-width: var(--mw);
	margin: 0 auto;
}

.page__pageTitle {
	border: 2px solid black;
	width: fit-content;
	height: 70px;
	padding: 0 20px;
	font-size: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	font-weight: 600;
	border-radius: 10px;
  margin-bottom: 60px;
}
