.formPopBkg {
	z-index: 6;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	overflow-y: scroll;
	padding: 30px 20px;
}

.formPop {
	width: 100%;
	min-width: 300px;
	max-width: 440px;
	background: #fff;
	border-top-left-radius: 21px;
	border-top-right-radius: 21px;
}

.formBody {
	padding: 15px 20px;
	padding-bottom: 30px;
	margin-top: 1vh;
	display: flex;
	flex-flow: column nowrap;
	gap: 20px;
}

.form__groupBtns {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 6vh;
	grid-gap: 10px;
	gap: 10px;
}

/* SELECT */
.form__TextField {
	margin-bottom: 15px;
	font-size: small;
	font-weight: 600;
}
.select select {
	width: 100%;
	height: 40px;
	padding: 5px 10px;
	border: 2px solid;
	margin-top: 3px;
}

/* SI OR NO */
.chooseDiv {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	text-align: center;
	grid-gap: 20px;
	gap: 20px;
	padding: 0 0 5px;
}
.btnChoose {
	padding: 10px;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 900;
	border: 1px solid #d3d3d3;
	cursor: pointer;
}
.btnChoose {
	padding: 10px;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 900;
	border: 1px solid #d3d3d3;
	cursor: pointer;
}
.btnChoose.btnCS {
	background: #4fb7bf;
	color: #fff;
}

/* bUTTONS */
button.form__btnCancel,
button.form__btnSuccess {
	border: 2px solid #52aac6;
	width: 100%;
	max-width: 200px;
	border-radius: 8px;
	text-align: center;
	padding: 8px 10px;
	font-weight: 600;
	cursor: pointer;
	background-color: white;
}
button.form__btnSuccess {
	background: none;
	color: #fff;
	background: #52aac6;
	font-weight: 500;
}
