.listWithCategories {
	border: 1px solid lightgray;
	background: white;
	border-radius: 10px;
	overflow: hidden;
	overflow-y: scroll;
	/* transition: var(--tran-03); */
	min-width: 300px;
	width: 100%;
	height: 300px;
}

.listWithCategories__itemHeader {
	background: white;
	color: black;
	padding: 12px 20px;
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 14px;
	font-weight: 500;
	border-bottom: 1px solid lightgray;
	cursor: pointer;
	/* transition: var(--tran-02); */
}

.listWithCategories__item.openCat .listWithCategories__itemHeader {
	background: var(--btn-color);
	color: white;
}

.listWithCategories__itemListProduct__item {
	display: grid;
	grid-template-columns: 45% 25% 25% 5%;
	padding: 13px 40px;
	width: 100%;
	border-bottom: 1px solid lightgray;
	align-items: center;
	color: gray;
	font-size: 14px;
	font-weight: 500;
	/* transition: var(--tran-03); */
}

span.itemListProduct__item__action {
	font-size: 25px;
	color: var(--btn-color);
	cursor: pointer;
}

.listWithCategories__item.openCat {
	/* height: auto; */
	/* transition: var(--tran-03); */
}

.listWithCategories__item {
	overflow: hidden;
	position: relative;
	height: 40px;
	transition: var(--tran-03);
	border-bottom: 1px solid lightgray;
}

.listWithCategories__itemListProduct {
	/* transition: var(--tran-03); */
}

.listWithCategories__itemListProduct__empty {
	padding: 15px;
	text-align: center;
}

.listWithCategories {
	height: 300px;
}

.listWithCategories__itemListProduct {
	opacity: 0;
	z-index: -1;
	transform: translateY(-100%);
	position: relative;
	background-color: white;
	transition: 0.1s ease all;
}

.listWithCategories__item.openCat .listWithCategories__itemListProduct {
	opacity: 1;
	transform: translateY(0%) !important;
	z-index: 1 !important;
	transition: 0.4s ease all;
}

.listWithCategories__item.openCat {
	height: auto;
	transition: 0.5s ease all;
}

.listWithCategories__SearchInput {
	padding: 20px 20px 10px;
	border-bottom: 1px solid lightgrey;
	position: relative;
}

svg.clear_input {
	position: absolute;
	right: 30px;
	top: 57px;
	cursor: pointer;
}