.pieInf {
	padding: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: auto;
	gap: 20px;
	width: 240px;
}

.pieInfIzqu {
	position: relative;
}

img.piePlantillaInf {
	width: 90px;
}

img.zi_1 {
	position: absolute;
	right: 12px;
	width: 33px;
}

img.zi_2 {
	position: absolute;
	width: 22px;
	right: 41px;
	top: 7px;
}

img.zi_3 {
	position: absolute;
	width: 23px;
	left: 21px;
	top: 15px;
}

img.zi_4 {
	position: absolute;
	width: 23px;
	left: 7px;
	top: 24px;
}

img.zi_5 {
	position: absolute;
	left: -1px;
	width: 20px;
	top: 40px;
}

img.zi_6 {
	position: absolute;
	right: 10px;
	width: 47px;
	top: 48px;
}

img.zi_7 {
	position: absolute;
	width: 84px;
	top: 49px;
	left: 2px;
}

img.zi_8 {
	position: absolute;
	width: 58px;
	left: 20px;
	top: 129px;
}

/*  */

img.di_1 {
	position: absolute;
	left: 2px;
	width: 33px;
}

img.di_2 {
	position: absolute;
	width: 23px;
	right: 46px;
	top: 5px;
}

img.di_3 {
	position: absolute;
	width: 24px;
	left: 47px;
	top: 14px;
}

img.di_4 {
	position: absolute;
	width: 23px;
	left: 61px;
	top: 26px;
}

img.di_5 {
	position: absolute;
	right: 8px;
	width: 20px;
	top: 41px;
}

img.di_6 {
	position: absolute;
	left: 1px;
	width: 46px;
	top: 49px;
}

img.di_7 {
	position: absolute;
	width: 83px;
	top: 50px;
	left: 4px;
}

img.di_8 {
	position: absolute;
	width: 57px;
	left: 13px;
	top: 130px;
}
