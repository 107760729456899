@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
	margin: 0;
	padding: 0;
	font-family: 'Quicksand', sans-serif;
	box-sizing: border-box;
}

:root {
	--btn-color: #52aac6;
	--h1-color: #52aac6;
	--text-color: 323232;
	--bkg-color: #eafdff;

	--tran-02: all 0.4s ease;
	--tran-03: all 0.3s ease;
	--tran-04: all 0.4s ease;
	--tran-05: all 0.5s ease;
	--mw: 1280px;
	/* --mw: 1080px; */
}

/* Button principal */
.btn-1,
.btn-2 {
	background-color: var(--btn-color);
	color: white;
	min-width: 60px;
	width: 100%;
	font-weight: 700;
	margin: 0;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	max-width: 250px;
	border-radius: 10px;
	cursor: pointer;
	padding: 10px;
}
.btn-2 {
	justify-content: flex-start;
	padding: 10px;
	width: fit-content;
	gap: 30px;
	padding-right: 40px;
}
.btn-2 .btn-2-icon{
	font-size: 38px;
}

/* Table */
table.tableListar {
	width: 100%;
	padding: 10px 20px;
	text-align: left;
	border-radius: 10px;
	border-collapse: collapse;
	margin-top: 40px;
}

tr.tableListarRowHeader {
	background: #fff !important;
}

.tableListar tr:nth-child(odd) {
	background: #eafdff;
}

td.tableListarCelda,
th.tableListarCeldaHeader {
	padding: 15px 10px 15px 15px;
	min-width: 100px;
}

.ListarHeader {
	display: flex;
	width: 100%;
	justify-content: left;
	align-items: end;
	gap: 15px;
}

.ListarHeader .form__TextField {
	width: 100%;
	max-width: 500px;
	margin: 0;
}

/* Repeat */
.center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex {
	display: flex;
}
.row {
	flex-direction: row;
}
.column {
	flex-direction: column;
}
.wrap {
	flex-wrap: wrap;
}
.space-arround {
	justify-content: space-around !important;
}

input[type="text"] {
  text-transform: capitalize !important;
}