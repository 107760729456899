.pieSup {
	padding: 10px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin: auto;
	gap: 20px;
	width: 240px;
}

.pieSupIzqu {
	position: relative;
}

.piePlantillaSup {
	width: 100px;
}

img.pi_1 {
	position: absolute;
	width: 34px;
	top: 0px;
	right: 1px;
}

img.pi_2 {
	position: absolute;
	width: 33px;
	right: 3px;
	top: 28px;
}

img.pi_3 {
	position: absolute;
	width: 28px;
	right: 32px;
	top: 8px;
}

img.pi_4 {
	position: absolute;
	width: 26px;
	right: 32px;
	top: 26px;
}

img.pi_5 {
	position: absolute;
	width: 24px;
	right: 51px;
	top: 17px;
}

img.pi_6 {
	position: absolute;
	width: 24px;
	right: 51px;
	top: 35px;
}

img.pd_6 {
  position: absolute;
  width: 26px;
  right: 24px;
  top: 33px;
}

img.pi_7 {
	position: absolute;
	width: 23px;
	right: 69px;
	top: 28px;
}

img.pi_8 {
	position: absolute;
	width: 24px;
	right: 66px;
	top: 42px;
}

img.pi_9 {
	position: absolute;
	width: 18px;
	right: 82px;
	top: 47px;
}

img.pi_10 {
	position: absolute;
	width: 18px;
	right: 78px;
	top: 57px;
}

img.pd_1 {
  position: absolute;
  width: 35px;
  right: 64px;
  top: 0;
}

img.pd_2 {
  position: absolute;
  width: 30px;
  right: 66px;
  top: 31px;
}

img.pd_3 {
  position: absolute;
  width: 26px;
  right: 42px;
  top: 9px;
}

img.pd_4 {
  position: absolute;
  width: 25px;
  right: 43px;
  top: 27px;
}

img.pd_5 {
  position: absolute;
  width: 26px;
  right: 23px;
  top: 14px;
}

img.pd_7 {
  position: absolute;
  width: 22px;
  right: 8px;
  top: 29px;
}

img.pd_8 {
  position: absolute;
  width: 22px;
  right: 11px;
  top: 44px;
}

img.pd_9 {
  position: absolute;
  width: 20px;
  right: -1px;
  top: 45px;
}

img.pd_10 {
  position: absolute;
  width: 17px;
  right: 4px;
  top: 58px;
}   