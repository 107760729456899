.verDetalle {
	color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	background: transparent;
	cursor: pointer;
}

.epagoButton {
	background: #2668ff;
	padding: 5px;
	border-radius: 5px;
	color: white;
	font-size: 12px;
	text-align: center;
	font-weight: 700;
	cursor: pointer;
	background: #fa4d4d;
}
.epago {
	background: #16d818;
	padding: 5px;
	border-radius: 5px;
	color: white;
	font-size: 12px;
	text-align: center;
	font-weight: 700;
}

.bkg-nPC {
	position: fixed;
	background: rgb(0 0 0 / 20%);
	width: 100%;
	height: 100%;
	z-index: 9;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px;
}

td.tableListarCelda.fechaCelda {
	white-space: nowrap;
}

.idCelda {
	padding: 15px !important;
	min-width: 10px !important;
}

.Listar__Header__InputSearch .txtField {
	margin-left: auto;
}
