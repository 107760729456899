.form__title {
	height: 50px;
	padding: 0 15px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background: linear-gradient(185.55deg, #2c90b0 14.98%, #58c2c4 79.99%);
	color: #fff;
	display: flex;
	align-items: center;
}
svg.form__titleIcon {
	font-size: 20px;
	margin-right: 10px;
}
span.form__titleText {
	font-weight: 500;
	font-size: 14px;
	margin-top: -3px;
}
button.form__titleActionClose {
	background: none;
	outline: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	color: #fff;
	font-size: 20px;
	margin-right: -3px;
	cursor: pointer;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
}

