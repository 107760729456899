.nc-section-1 .homePageBox {
	width: 100%;
	height: 100%;
	margin: auto;
}

.ncs3__PacienteData {
	display: flex;
	align-items: end;
	gap: 15px;
	margin-bottom: 40px;
}

.ncs3__PacienteData .form__TextField {
	margin: 0;
	width: 100%;
	max-width: 400px;
}

.sectionsBox__sectionsList {
	display: flex;
	flex-flow: row nowrap;
	gap: 15px;
}

span.sectionsList__ListItem {
	border: 1px solid lightgrey;
	padding: 8px 20px;
	width: 200px;
	text-align: center;
	border-radius: 5px;
	border-bottom: 0;
	background-color: white;
	cursor: pointer;
}

.sectionsBox__Body {
	border: 1px solid lightgrey;
	border-color: rgb(169, 168, 168);
	margin-top: -3px;
	min-height: 400px;
	border-radius: 5px;
	border-top-left-radius: 0;
	position: relative;
	z-index: 2;
	padding: 25px;
	display: flex;
	flex-flow: row;
	gap: 20px;
}

span.sectionsList__ListItem.active {
	position: relative;
	z-index: 3;
	border-color: rgb(169, 168, 168);
}

label.DatosCitaTotal__label {
	font-size: 30px;
	font-weight: 500;
}

.DatosCitaTotal__DivInput {
	position: relative;
}

input.DatosCitaTotal__TotalIn {
	height: 75px;
	text-align: center;
	font-size: 30px;
	width: 140px;
	border-radius: 5px;
	border: 1px solid lightgray;
}

.DatosCita__Total.flex.row {
	align-items: center;
	gap: 20px;
}

span.DatosCitaTotal__prec {
	position: absolute;
	top: 8px;
	left: 8px;
	color: #9e9e9e;
}

.actionsPage {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 25px 0;
}

.nc-section-2 .btn-2 {
	margin-left: auto;
}

.estadoPies {
	border: 3px solid black;
	width: 280px;
	height: fit-content;
	padding: 15px;
}

.sectionDataCita {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	height: fit-content;
	align-items: center;
	justify-content: space-between;
	max-width: 700px;
	gap: 10px;
	margin: auto;
	padding: 0 30px;
}

.sectionDataCita .form__TextField {
	width: 100%;
	max-width: 275px;
}

.sectionDataCita .form__textArea,
.sectionDataCita .form__textArea textarea {
	width: 100%;
	max-width: none;
	height: 150px;
}

.sectionDataCita .form__textArea textarea {
	padding: 10px;
	border: 1px solid lightgray;
	outline: none;
}

.sectionsBox__Body.sectionBox__Medicamentos {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.detailCart {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	grid-column-start: 1;
	grid-column-end: 3;
	width: 100%;
	gap: 40px;
	padding: 10px 20px;
}

.total {
	display: flex;
	gap: 18px;
}

input.total__show {
	text-align: right;
	padding: 8px;
	width: 135px;
	border-radius: 5px;
	border: 1px solid lightgray;
	font-weight: 400;
	font-size: 14px;
	outline: none;
	position: relative;
}

.total__text {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
}

.total__text {
	position: relative;
}
.total__text::after {
	content: 'S/.';
	position: absolute;
	right: -45px;
	z-index: 2;
	font-size: 12px;
	color: #999;
}

.sectionsBox__Body.section__Paquetes {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.paquetesSuscritos {
	border: 1px solid lightgray;
	border-radius: 10px;
	overflow-y: scroll;
	height: 300px;
	position: relative;
}

.paquetesSuscritos__title {
	background: var(--btn-color);
	color: white;
	display: flex;
	padding: 13px 20px;
	align-items: center;
	font-size: 10px;
	font-weight: 100;
	gap: 15px;
}

h2.paquetesSuscritos__titleText {
	font-size: 14px;
	font-weight: 500;
}

svg.paquetesSuscritos__titleIcon {
	font-size: 14px;
}

.paquetesCart {
	padding: 13px 10px;
}

.paquetesCart__Item {
	display: grid;
	grid-template-columns: 40% 25% 30% 5%;
	border-bottom: 2px solid lightgrey;
	padding: 10px 5px;
	padding-bottom: 15px;
	color: gray;
	font-size: 13px;
	font-weight: 500;
}

svg.paquetesCart__ItemIcon {
	background: var(--btn-color);
	color: white;
	border-radius: 8px;
	font-size: 16px;
	cursor: pointer;
}

.paquetesCartSend__Item {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 20px;
	border: 1px solid lightgrey;
	border-radius: 15px;
	height: 230px;
	font-size: 14px;
	position: relative;
}

.paquetesCartSend {
	padding: 15px;
	display: grid;
	gap: 15px;
}

.paquetesCartSend__Item__Section1 {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	gap: 10px;
}

.paquetesCartSend__ItemSeTitle {
	font-size: 16px;
	text-decoration: underline;
	font-weight: 700;
}

.paquetesCartSend__Item__Section2 {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: flex-end;
}

button.btn-line {
	color: black;
	background: white;
	border: 2px solid black;
	padding: 5px 10px;
	border-radius: 5px;
	font-weight: 700;
}

svg.paquetesCartSend__close {
	cursor: pointer;
	position: absolute;
	right: -5px;
	color: red;
	font-size: 20px;
	top: -5px;
}
