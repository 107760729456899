.bkg-Dp {
	position: fixed;
	z-index: 8;
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 30%);
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dPac {
	margin: 0;
	font-family: 'Quicksand', sans-serif;
	box-sizing: border-box;
	width: 80%;
	min-width: 300px;
	background: white;
	height: 90%;
	min-height: 400px;
	overflow-y: scroll;
	padding: 40px;
	position: relative;
	border-radius: 25px;
}

.dPac::-webkit-scrollbar {
	display: none;
}

.dPacClose {
	position: fixed;
	font-size: 21px;
	color: red;
	cursor: pointer;
	top: 7%;
	right: 11%;
}

h2.dPacTitle {
	text-decoration: underline;
}

.dPacBody {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
	gap: 20px;
	padding-top: 30px;
}

.dpacItem {
	margin: 0;
	font-family: 'Quicksand', sans-serif;
	box-sizing: border-box;
	width: 100%;
	box-shadow: 0px 0px 10px 1px hsl(0deg 0% 0% / 10%);
	border-radius: 20px;
	padding: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	gap: 10px;
}

.dpacItTxt {
	display: grid;
}

.dpacItem input {
	padding: 10px;
	font-weight: 600;
	margin-top: 3px;
	border-radius: 6px;
	border: 1px solid lightgray;
}

.dpacItTxt.dpacPrecio {
	margin: 0;
	font-family: 'Quicksand', sans-serif;
	box-sizing: border-box;
	grid-column-start: 2;
	grid-row-start: 5;
	grid-row-end: 8;
	display: flex;
	padding: 10px;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}

.dpacMedList {
	display: grid;
	grid-template-columns: 1fr;
	border: 1px solid lightgrey;
	padding: 10px;
	border-radius: 10px;
}

.dPacBody .estadoPies {
	grid-column-start: 2;
	grid-row-start: 1;
	margin: auto;
	padding: 0;
	grid-row-end: 5;
	transform: scale(0.7);
}

.dpacItTxt.dpacPrecio label {
	font-weight: 700;
	margin-bottom: 10px;
}

span.precioTratTotal {
	font-size: 80px;
	text-align: center;
	border: 2px solid lightslategray;
	border-radius: 10px;
	width: 100%;
}

h2.dpacMedTit {
	font-size: 16px;
	text-decoration: underline;
}

.dPacBody .paquetesCartSend__Item {
	grid-template-columns: 1fr !important;
	gap: 10px;
	height: fit-content;
}

.dpacItTxt.dpacPrecio {
	grid-row-start: 9;
	grid-column-start: 1;
	grid-column-end: 3;
}

.dpacPreTr textarea {
	min-height: 100px;
}

.estadoPies {
	grid-row-start: 1;
	grid-row-end: 7 !important;
}

.dpacItTxt.dpacPreTr.dPacTxtAreaD {
	grid-column-start: 1;
	grid-column-end: 3;
}

.dpacItem button.btn-1 {
	grid-column-start: 2;
	grid-row-start: 1;
	margin-left: auto;
}
