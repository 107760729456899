.ventaMed button.btn-1 {
	margin: auto;
	margin-top: 50px;
}

.nEntrada .ninputT {
	font-size: 95px;
	height: auto;
	text-align: center;
	font-weight: 500;
	margin-bottom: 10px;
}

.ventaMed.fechaFormTxtField {
	max-width: 300px;
}

.dpacMedList.detalleVentaListMed {
	padding: 23px;
}

.detalleVentaListMed .form.formPop {
	border-radius: 15px;
	padding: 20px;
	max-width: 500px;
}

.detalleVentaListMed .dpacMedListD {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid lightgray;
}

.detalleVentaListMed h2.dpacMedTit {
	text-decoration: none !important;
	font-size: 30px;
	border-bottom: 1px solid lightgrey;
	margin-bottom: 20px;
}

.detalleVentaListMed .dpacMedList {
	padding-bottom: 30px;
	border: none;
}

.detalleVentaListMed button.form__titleActionClose {
	/* background: red; */
	color: red;
}