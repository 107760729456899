/* small to tall */
#Login {
	padding: 25px 15px;
}

/* Ocultamos el lado derecho del login */
.bkgLoginRight {
	display: none;
}

/* Ajustamos Logo */
.logoFormLogin {
	width: 250px;
	margin-top: 2rem;
}

/* Ajustamos el formulario */
.FormLogin {
	/* Centramos */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Ajustamos el titulo del Login */
.FormLogin h3 {
	color: #52aac6;
	font-weight: 500;
	margin-top: 5vh;
}

/* Ajustamos el subtitulo del login */
.FormLogin p {
	text-align: center;
	margin-top: 12px;
	margin-bottom: 25px;
}

/* TextFields */
.TextField {
	position: relative;
	width: 240px;
	margin: 1rem;
}
.TextField input {
	width: 100%;
	outline: none;
	border: none;
	border: 1px solid lightslategray;
	border-radius: 5px;
	height: 40px;
	/* Le damos espacio para los icons */
	padding-left: 40px;
}
.TextField label {
	display: block;
	margin-bottom: 10px;
}

/* Ajustamos Icons */
.icon-l {
	position: absolute;
	bottom: 12px;
	left: 10px;
}
.icon-r {
	position: absolute;
	bottom: 12px;
	right: 10px;
}

/* Boton */
.buttonLogin {
	cursor: pointer;
	background-color: #4fb7bf;
	color: white;
	width: 240px;
	outline: none;
	border: none;
	height: 40px;
	border-radius: 10px;
	margin: 2rem 0;
	font-weight: 900;
}

/* footer */
footer {
	margin-top: 2.5rem;
	text-align: center;
}

/* Fixed to display design */
@media screen and (min-width: 1024px) {
	/* Mostramos el lado derecho del login */
	.bkgLoginRight {
		display: block;
	}

	/* Dividimos la pantalla en dos */
	#Login {
		display: grid;
		grid-template-columns: 50% 50%;
		padding: 0;
	}

	/* Arreglamos la image del login */
	.bkgLoginRight {
		width: 100%;
		height: 102vh;
	}
	.bkgLoginRight img {
		object-fit: fill;
		width: 100%;
		height: 100%;
	}

	/* Actualizamos el padding del Formulario y no centramos verticalmente */
	.FormLogin {
		padding: 5vh;
		justify-content: flex-start;
	}

	/* Actualizamos el fontsize */
	.FormLogin p {
		font-size: small;
		margin-top: 7px;
		margin-bottom: 7vh;
	}

	/* Acomodamos TextField */
	.TextField {
		width: 400px;
		margin-bottom: 3vh;
	}

	.TextField label {
		font-size: smaller;
		margin-bottom: 13px;
	}

	.TextField input {
		border-radius: 12px;
	}

	/* Ajustamos a la izquierda en checkbox */
	.chbxLabel {
		margin-right: auto;
		margin-right: 18vw;
		margin-top: 1vh;
	}

	/* Actualizamos el width del boton */
	#Login button {
		width: 400px;
		margin-top: 8vh;
	}

	/* Actualizamos el fontsize de Footer */
	footer {
		font-size: small;
	}
}
