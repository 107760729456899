.listCart {
	border: 1px solid lightgray;
	border-radius: 10px;
	/* display: flex; */
	flex-flow: column nowrap;
	overflow: hidden;
	overflow-y: scroll;
	width: 100%;
	min-width: 300px;
	height: 300px;
}
.listCartItem {
	border-bottom: 1px solid lightgray;
	padding: 15px 20px;
	display: grid;
	grid-template-columns: 60% 35% 5%;
}

.listCartItem__data {
	display: flex;
	gap: 10px;
	flex-flow: column;
}

span.listCartItem__name {
	font-size: 14px;
	font-weight: 600;
	color: #999;
}

.listCartItem__action {
	display: flex;
	flex-flow: row;
	gap: 0px;
	align-items: flex-start;
}

svg.listCartItem__action__Act {
	color: gray;
	font-size: 20px;
	margin-top: 4px;
	margin-left: 1px;
	cursor: pointer;
}

svg.listCartItem__action__Act.listCartItem__action__ActDown {
	font-size: 15px;
	margin-top: 6px;
	margin-right: 4px;
}

span.listCartItem__action__cant {
	border: 2px solid rgb(169, 168, 168);
	padding: 3px 8px;
	font-size: 14px;
	border-radius: 5px;
	color: rgb(169, 168, 168);
	font-weight: 600;
}

svg.listCartItem__delete {
	cursor: pointer;
}
