.page.homePage {
	display: flex;
	justify-content: center;
	align-items: center;
}

.boxButton {
	cursor: pointer;
	width: 250px;
	height: 270px;
	background: var(--btn-color);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	color: white;
	font-weight: 800;
	font-size: 20px;
	padding: 20px;
	text-align: center;
	transition: var(--tran-02);
}

.boxButton:hover {
	transform: scale(1.05);
}

svg.boxButton__boxIcon {
	font-size: 170px;
}

.homePageBox {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-flow: row wrap;
	gap: 20px;
	max-width: 1055px;
}
