.form__TextFieldLabel {
	display: grid;
	text-align: left;
	grid-template-columns: 30px auto;
	margin-bottom: 10px;
}
svg.iconLabel {
	font-size: 16px;
}
.form__TextFieldLabel label {
	font-size: 15px;
	margin-top: -2px;
	font-weight: 500;
}
.form__TextField input,
.form__TextField select {
	width: 100%;
	height: 35px;
	padding: 5px 15px;
	border-radius: 5px;
	outline: none;
	border: 1px solid #d3d3d3;
}
.form__TextField {
	margin-bottom: 15px;
	font-size: small;
	font-weight: 600;
	position: relative;
}
