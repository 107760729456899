.txtField {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	max-width: 280px;
}

label.txtField__label {
	color: #464646;
	font-size: 13px;
}

.selectField,
.txtField input[type='date'],
.txtField input[type='text'] {
	width: 100%;
	/* max-width: 300px; */
	padding: 10px;
	outline: none;
	border: 1px solid #b3b3b3;
	border-radius: 10px;
	height: 45px;
	margin-left: 10px;
	margin-top: 3px;
}
