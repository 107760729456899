.totalIngresos {
  border: 1px solid black;
  padding: 15px;
  width: fit-content;
  border-radius: 10px;
  text-align: center;
  margin-left: auto;
  margin-top: 10px;
}

.totalIngresos span{
  font-size: 2rem;
}
