/* Sidebar Container */
.sidebar {
	position: fixed;
	z-index: 5;
	border-top-right-radius: 13px;
	border-bottom-right-radius: 10px;
	border-top-left-radius: 13px;
	border-bottom-left-radius: 10px;
	top: 5px;
	left: 5px;
	height: calc(100vh - 10px);
	width: 50px;
	background-color: #4fb7bf;
	transition: all 0.3s;
	background-size: cover;
	padding: 2px;
	padding-top: 80px;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: center;
	overflow: visible;
	overflow-y: scroll;
}
.sidebar-open {
	width: 250px;
	padding: 40px 35px;
	padding-bottom: 15px;
}

/* Ocultar el Scroll */
.sidebar::-webkit-scrollbar {
	display: none;
}

/* Sidebar-button-open */
.sidebarBtnCtrl {
	position: fixed;
	top: 15px;
	left: 13px;
	border: none;
	background-color: rgba(255, 255, 255, 0.242);
	color: white;
	height: 35px;
	width: 35px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.sidebarBtnCtrl.sBtnExpanded {
	left: 210px;
}

/* Sidebar Logo */
img.sidebar-logo {
	/* display: none; */
	width: 25px;
}

.sidebar-open img.sidebar-logo {
	width: 110px;
	display: block;
}

.sidebarUl {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	gap: 10px;
}

.btn-nav-li {
	cursor: pointer;
	display: flex;
	justify-content: left;
	align-items: center;
	height: 45px;
	width: 100%;
	align-items: center;
	padding: 0 10px;
	gap: 15px;
	color: white;
	font-size: 14px;
	font-weight: 600;
	transition: var(--tran-02);
}

.btn-nav-li:hover {
	background: rgba(255, 255, 255, 0.3);
	border-radius: 10px;
}

.btn-nav-li.active {
	background: rgba(255, 255, 255, 0.3);
	border-radius: 10px;
}

.sidebar .sidebar-logo {
	width: 25px;
}

.sidebar .btn-nav-li span {
	display: none;
}

.sidebar .btn-nav-li {
	justify-content: center;
	font-size: 18px;
}

.sidebar.sidebar-open .btn-nav-li span {
	display: block;
}

.sidebar.sidebar-open .btn-nav-li {
	justify-content: left;
	font-size: 14px;
}

.sidebar.sidebar-close .btn-nav-li:hover span {
	position: absolute;
	z-index: 1;
	color: black;
	display: block !important;
	white-space: nowrap;
	left: 80%;
	text-align: left;
	background: #ffffff;
	font-size: 12px;
	padding: 4px;
	border-radius: 3px;
	margin-top: 35px;
	border: 1px solid lightgray;
	transition: var(--tran-02);
}
.sidebar.sidebar-close {
	overflow: visible;
}
